import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import {
  PatchFormData,
  ResetSimulationStateAction,
  SetSimulatedDataAction,
  SetSizingMethod,
} from './simulation.meta.actions'
import {
  ISimulatedData,
  SimulationMetaStateModel,
} from './simulation.meta.model'

const INITIAL_STATE: SimulationMetaStateModel = {
  sizingMethod: 'consumption',
  formData: null,
  simulatedData: null,
}

@State<SimulationMetaStateModel>({
  name: 'simulationMeta',
  defaults: { ...INITIAL_STATE },
})
@Injectable()
export class SimulationMetaState {
  @Selector()
  public static getState(
    state: SimulationMetaStateModel,
  ): SimulationMetaStateModel {
    return state
  }

  @Selector()
  public static getSizingMethod(state: SimulationMetaStateModel): string {
    return state.sizingMethod
  }

  @Selector()
  public static getFormData(state: SimulationMetaStateModel): any {
    return state.formData
  }

  @Selector()
  public static getSimulatedData(
    state: SimulationMetaStateModel,
  ): ISimulatedData {
    return state.simulatedData
  }

  @Action(ResetSimulationStateAction)
  resetState(ctx: StateContext<SimulationMetaStateModel>): void {
    ctx.setState({ ...INITIAL_STATE })
  }

  @Action(SetSizingMethod)
  setSizingMethod(
    ctx: StateContext<SimulationMetaStateModel>,
    action: SetSizingMethod,
  ): void {
    ctx.patchState({ sizingMethod: action.payload })
  }

  @Action(PatchFormData)
  patchFormData(
    ctx: StateContext<SimulationMetaStateModel>,
    action: PatchFormData,
  ): void {
    ctx.patchState({ formData: action.payload })
  }

  @Action(SetSimulatedDataAction)
  setSimulatedData(
    ctx: StateContext<SimulationMetaStateModel>,
    action: SetSimulatedDataAction,
  ): void {
    ctx.patchState({ simulatedData: action.payload })
  }
}
