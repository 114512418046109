import { ISimulatedData } from './simulation.meta.model'

export class ResetSimulationStateAction {
  public static readonly type = '[SimulationMeta] Reset to Initial State'
  constructor() {}
}

export class SetSizingMethod {
  public static readonly type = '[SimulationMeta] Set Sizing Method'
  constructor(public payload: any) {}
}

export class PatchFormData {
  public static readonly type = '[SimulationMeta] Patch Form Data'
  constructor(public payload: any) {}
}

export class SetSimulatedDataAction {
  public static readonly type = '[SimulationMeta] Set Simulated Data'
  constructor(public payload: ISimulatedData) {}
}
